import { THEME } from '@lyon/utils/theme';

export const NAVIGATION_ITEM = {
  width: '100%',

  [THEME.media.large]: {
    width: '240px',
  },

  [THEME.media.xlarge]: {
    width: '480px',
  },
};
