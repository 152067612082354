// ANCHOR React
import React from 'react';

// ANCHOR Next
import { useRouter } from 'next/router';

// ANCHOR Base
import { Block } from 'baseui/block';
import { Input, SIZE } from 'baseui/input';
import { ParagraphXSmall } from 'baseui/typography';

// ANCHOR Hooks
import { useValidState } from '@lyon/utils/hooks/useValidState';
import { useConstantCallback } from '@lyon/utils/hooks/useConstantCallback';
import { useIsomorphicEffect } from '@lyon/utils/hooks/useIsomorphicEffect';
import { useSentryExceptionHandler } from '@lyon/utils/hooks/useSentryExceptionHandler';

// ANCHOR Validation
import { SEARCH_VALIDATOR } from './validation';

// ANCHOR Constants
import { PLACEHOLDER } from './constants';

// ANCHOR Styles
import { BLOCK, TOOLTIP, TEXT } from './styles';

export const LyonNavBarSearchQuery = React.memo(() => {
  const catchException = useSentryExceptionHandler();
  const router = useRouter();

  const [search, setSearch, error] = useValidState<string>('', SEARCH_VALIDATOR);

  const onChange = useConstantCallback((params: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(params.target.value);
  });

  const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!error) {
      await router.push({
        pathname: '/results',
        query: { search },
      });
    }
  }, [router, search, error]);

  useIsomorphicEffect(() => {
    if (search) {
      router.prefetch('/results', `/results?search=${search}`)
        .catch((err) => {
          catchException(err);
        });
    }
  }, [router, search]);

  return (
    <form onSubmit={onSubmit}>
      <Block overrides={BLOCK}>
        <Input
          value={search}
          placeholder={PLACEHOLDER}
          size={SIZE.default}
          onChange={onChange}
          error={!!error}
          required
        />
        {error && (
          <Block overrides={TOOLTIP}>
            <ParagraphXSmall overrides={TEXT}>
              {error.message}
            </ParagraphXSmall>
          </Block>
        )}
      </Block>
    </form>
  );
});
