import { BlockOverrides } from 'baseui/block';
import { THEME } from '@lyon/utils/theme';

export const BLOCK: BlockOverrides = {
  Block: {
    style: {
      position: 'relative',
      maxWidth: '100%',
    },
  },
};

export const TOOLTIP: BlockOverrides = {
  Block: {
    style: {
      position: 'absolute',
      paddingLeft: '8px',
      paddingRight: '8px',
      width: 'calc(100% - 16px)',
      backgroundColor: THEME.colors.mono400,
    },
  },
};

export const TEXT: BlockOverrides = {
  Block: {
    style: {
      color: THEME.colors.black,
    },
  },
};
