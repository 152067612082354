// ANCHOR React
import React from 'react';

// ANCHOR Hooks
import { useConstantCallback } from './useConstantCallback';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;
type StateSupplier<T> = T | (() => T);
type StateValidator<T> = (state: T) => boolean;
type StateTuple<T> = [T, SetState<T>, Error | undefined, SetState<Error | undefined>];

export function useValidState<T>(
  initialState: StateSupplier<T>,
  validator: StateValidator<T>,
): StateTuple<T> {
  const [state, setState] = React.useState(initialState);
  const [error, setError] = React.useState<Error | unknown | undefined>();

  const dispatch = useConstantCallback<React.Dispatch<React.SetStateAction<T>>>((value) => {
    setState((current) => {
      const newValue = typeof value === 'function'
        ? (value as (prevState: T) => T)(current)
        : value;

      try {
        validator(newValue);
        setError(undefined);
      } catch (err) {
        setError(err);
      }

      return newValue;
    });
  });

  const err = error as Error | undefined;

  return [state, dispatch, err, setError];
}
