// ANCHOR React
import React from 'react';

// ANCHOR Base
import { withStyle } from 'baseui';
import { StyledNavigationItem } from 'baseui/header-navigation';

// ANCHOR NavBarSearch components
import { LyonNavBarSearchQuery } from './LyonNavBarSearchQuery';

// ANCHOR Styles
import { NAVIGATION_ITEM } from './styles';

const SearchContainer = withStyle(StyledNavigationItem, NAVIGATION_ITEM);

export function LyonNavBarSearch() {
  return (
    <SearchContainer>
      <LyonNavBarSearchQuery />
    </SearchContainer>
  );
}
